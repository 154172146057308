var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-breadcrumb',{staticClass:"mb-2"},[_c('b-breadcrumb-item',{attrs:{"to":"/orders/punctual"}},[_vm._v("Commandes")]),_c('b-breadcrumb-item',[_c('router-link',{attrs:{"to":{
        name: 'admin-orders-punctual-details',
        params: { id: this.$route.params.id },
        query: { packageId: this.$route.query.packageId },
      }}},[_vm._v("Détails")])],1),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("Offre")])],1),_c('b-card',{attrs:{"title":"Faire une offre"}},[_c('b-row',[_c('b-col',[_c('validation-observer',{ref:"newOfferForm",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_vm._l((_vm.forms),function(form,index){return _c('b-form',{key:index,staticClass:"p-2",attrs:{"autocomplete":"off"}},[_c('b-form-group',{attrs:{"label":"Professionnel fournissant le service de ","label-for":"role"}},[_c('validation-provider',{attrs:{"name":"pro","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [(_vm.proOptions.length == 1 && _vm.proOptions[0].disabled)?_c('b-form-select',{attrs:{"id":"professional_id","options":_vm.proOptions},model:{value:(form.professional_id),callback:function ($$v) {_vm.$set(form, "professional_id", $$v)},expression:"form.professional_id"}}):_c('v-select',{attrs:{"id":"role","reduce":function (professional) { return professional.id; },"label":"full_name","options":_vm.proOptions,"multiple":""},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_c('span',[_vm._v("Chargement ...")])]},proxy:true},{key:"option",fn:function(ref){
      var full_name = ref.full_name;
      var address = ref.address;
      var phone_number = ref.phone_number;
      var profile_image = ref.profile_image;
return [_c('b-row',[_c('b-col',{staticClass:"offset-md-3",attrs:{"md":"3"}},[_c('b-avatar',{attrs:{"size":"90px","rounded":"","src":profile_image}})],1)],1),_c('br'),_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('b-row',[_c('b-col',[_c('span',{staticClass:"text-white"},[_vm._v(" Nom & Prénom: "),_c('strong',{staticClass:"text-info"},[_vm._v(" "+_vm._s(full_name)+" ")])])]),_c('br')],1),_c('b-row',[_c('br'),_c('b-col',[_c('span',{staticClass:"text-white"},[_vm._v(" Numéro de téléphone : "),_c('strong',{staticClass:"text-info"},[_vm._v(" "+_vm._s(phone_number != null ? phone_number : "Non renseigné")+" ")])])]),_c('br'),_c('b-col',[_c('span',{staticClass:"text-white"},[_vm._v("Address "),_c('strong',{staticClass:"text-info"},[_vm._v(" "+_vm._s(address != null ? address : "Non renseigné"))])])])],1)],1)],1),_c('hr'),_c('br')]}}],null,true),model:{value:(form.professional_id),callback:function ($$v) {_vm.$set(form, "professional_id", $$v)},expression:"form.professional_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('validation-provider',{attrs:{"name":"prix","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Prix","label-for":"designation"}},[_c('b-form-input',{attrs:{"id":"price","type":"number","state":errors.length > 0 ? false : null,"trim":""},model:{value:(form.price),callback:function ($$v) {_vm.$set(form, "price", $$v)},expression:"form.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)}),_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center ml-1 mb-2",attrs:{"md":"4"},on:{"click":function($event){return _vm.applyMakeOfferAction()}}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","disabled":_vm.isMakingOfferLoading}},[_vm._v(" "+_vm._s(!_vm.isMakingOfferLoading ? "Soumettre" : "Chargement...")+" ")])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }